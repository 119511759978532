import { Link, graphql } from "gatsby";
import React from "react";

import Layout from "../components/layout";
import Masthead from "../components/masthead";
import SEO from "../components/seo";
import Slices from "../components/slices/silces";

export const query = graphql`
	{
		prismic {
			allHome_pages(uid: null) {
				edges {
					node {
						page_title
						mastheadContainer {
							masthead
							sub_title
							text
							title
							button_label
							button_target {
								... on PRISMIC_Page {
									title
									_linkType
									_meta {
										uid
										type
									}
								}
							}
						}
						body {
							... on PRISMIC_Home_pageBodySplash_image {
								type
								primary {
									left_image
									left_button_target {
										... on PRISMIC_Page {
											title
											_meta {
												type
												uid
											}
											_linkType
										}
									}
									left_image_title
									left_button_title
									right_button_target {
										... on PRISMIC_Page {
											title
											_meta {
												type
												uid
											}
											_linkType
										}
									}
									right_image
									right_image_title
									right_button_title
								}
							}
							... on PRISMIC_Home_pageBodyHorizontal_line {
								type
								primary {
									optional_line_text
								}
							}
							... on PRISMIC_Home_pageBodyLogo_group {
								type
								fields {
									image_url
								}
							}
						}
						_meta {
							uid
							id
						}
					}
				}
			}
		}
	}
`;

const IndexPage = props => {
	const doc = props.data.prismic.allHome_pages.edges.slice(0, 1).pop();
	return (
		<Layout>
			<SEO title="Home" />
			<Masthead masthead={doc.node.mastheadContainer} />
			<Slices slices={doc.node.body} />
		</Layout>
	);
};

export default IndexPage;
