import { RichText } from "prismic-reactjs";
import React, { ReactElement } from "react";

import PrismicHref from "../prismicHref";
import classes from "./masthead.module.css";

interface Props {
	masthead: any;
}

const Masthead = (props: Props): ReactElement => {
	const mastheadContainer = props.masthead[0];
	return (
		<div
			className={classes.masthead}
			style={{
				background: `linear-gradient(rgba(3, 4, 17, 0.6), rgba(3, 4, 17, 0.6)), url('${mastheadContainer.masthead.url}')`,
			}}
		>
			<div className={classes.mastheadContent}>
				<h2>{RichText.asText(mastheadContainer.title)}</h2>
				<h4>{RichText.asText(mastheadContainer.sub_title)}</h4>
				<p>{RichText.asText(mastheadContainer.text)}</p>
				{mastheadContainer.button_label &&
					mastheadContainer.button_label[0] && (
						<PrismicHref
							className={classes.mastheadButton}
							link={mastheadContainer.button_target}
						>
							{mastheadContainer.button_label[0].text}
						</PrismicHref>
					)}
			</div>
		</div>
	);
};

export default Masthead;
